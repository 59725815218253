import type Experiments from '@wix/wix-experiments'
import type { Platform } from './contract'

export type Features = ReturnType<typeof createFeatures>

export const createFeatures = ({
  experiments,
  settings: { env },
}: {
  experiments: Experiments
  settings: Platform['settings']
}) => ({
  get fes() {
    return experiments.enabled('specs.wixDataViewer.EnableFES')
  },
  get warmupData() {
    return env.live
  },
  get newCurrentPageIndex() {
    return experiments.enabled('specs.wixDataViewer.NewCurrentPageIndex')
  },
  get automationsClientV2() {
    return experiments.enabled('specs.wixDataClient.AutomationsClientV2')
  },
  get filteredResultsCounter() {
    return experiments.enabled(
      'specs.wixDataClient.BlitzNoFilteredResultsCounter',
    )
  },
  get useCloudDataUrlWithBaseExternalUrl() {
    return experiments.enabled(
      'specs.wixDataViewer.UseCloudDataUrlWithBaseExternalUrl',
    )
  },
  get fetchOnlyConnectedFields() {
    return (
      experiments.enabled('specs.wixDataViewer.fetchOnlyConnectedFields') &&
      !env.veloCodeIsPresentOnCurrentPage &&
      !env.blocksAppIsPresentOnSite &&
      !env.livePreview
    )
  },
})
